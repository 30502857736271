body {
    a {
        &:hover {
            text-decoration: underline;
        }
    }
    padding:9rem;
    div.main {
        .container {
            
            text-align:center;
            &.about {
                p {
                    width:70%;
                    margin:0 auto;
                    line-height:1.3;
                    font-weight:bold;
                    font-size:2rem;
                }
            }
    
            &.exhibition {
                p {
                    padding:1rem 0;
                    font-weight:bold;
                }
            }

            &.art-display {
                div {
                    width:60%;
                    margin:4rem auto;
                    img {
                        width:100%;
                        height:auto;
                    }
                    p {
                        font-size:1.2rem;
                        text-align:left;
                    }
                }
            }
    
        }
    }
    
}
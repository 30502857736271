[class^='video-react'] {
    @include font-press-start();     
}

.hello {
    text-align: center;
    button {
        padding:1rem .5rem;
        border-radius: 0;
        @include font-press-start();
    }
}
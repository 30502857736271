header {
    text-align:center;
    div.container {
        div.brand {
            a.logo {
                display:block;
                width:100%;
                margin:0 auto;
                @include font-press-start();
                font-size:3.1rem;
                color:$site-black;
            }

        }
        nav {
            padding:4rem 0 3rem 0;
            a {
                color:$site-black;
                display:inline-block;
                font-size:1.7rem;
                letter-spacing: .1rem;
                margin:0 2rem;
                text-transform: uppercase;
                font-weight:700;
            }
        }
    }
}
footer {
    text-align:center;
    div.container {
        div.social-media {
            a {
                margin:0 1rem;
                font-size:0;
                display:inline-block;
                svg {
                    height:3rem;
                    width:auto;
                }
                &:hover {
                    g {
                        path {     
                            fill:$grey;
                        }
                    }
                }
            }
        }
    }
    padding:5rem 0 3rem 0;
}
:root {
    font-size:10px;
}

@font-face {
    font-family: 'Press_Start_2P';
    src: local('PressStart2P-Regular'), url('../fonts/Press_Start_2P/PressStart2P-Regular.ttf') format('truetype');
}


@mixin  font-press-start() {
    font-family: 'Press_Start_2P', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;;
    
}
@mixin font-default() {
    font-family: Arial, Helvetica, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$black: #000000;
$site-black: #414141;
$grey: lighten($black,20%);
$white: #ffffff;

body {
    padding:0;
    margin:0;
    font-size:1.6rem;
    @include font-default();
    color:$site-black;

    a {
        text-decoration: none;
        color:$black;
        
    }

    h1 {
        @include font-default();
        letter-spacing: .2rem;
        font-weight:700;
        color:$site-black;
        font-size:2.2rem;
        text-transform: uppercase;
    }

    h2 {
        @include font-press-start();
        color:$site-black;
        font-size:3.1rem;
    }
}